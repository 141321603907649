import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Easily add Chatkit to your existing app. With our well-documented SDKs that will help you focus on what you do best, build great apps.`}</p>
    <p>{`Engage & retain your users now.  We have everything you need to get you set up`}</p>
    <p>{`DOCS
Full reference of our APIs`}</p>
    <p>{`Learn more
TUTORIALS
Extensive tutorials for our APIs`}</p>
    <p>{`Learn more`}</p>
    <p>{`TRUSTED BY GIANTS.
LOVED BY STARTUPS.
Chatkit is the most extensible and scalable platform designed to increase user engagement and retention by helping you bring powerful chat experiences to your apps quickly.`}</p>
    <p>{`GDPR
Pusher is GDPR compliant`}</p>
    <p>{`SCALABLE
Built to seamlessly scale`}</p>
    <p>{`REALTIME EXPERTS
Delighting users since 2011`}</p>
    <p>{`Engage & retain your users now. See Chatkit in action.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      